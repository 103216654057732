.overview-annotation-item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 33%;
    min-width: 350px;
    max-width: 50%;
}

@media (max-width: 768px) {
    .overview-annotation-item {
        flex-basis: 100%; /* For smaller screens, 2 items per row */
        max-width: 100%; /* For smaller screens, 2 items per row */
        min-width: 200px;
    }
}
